@keyframes sphere-spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}

.sphere-spinner {
  color: rgb(156, 163, 175);
  animation: sphere-spinner-spin 1s linear infinite;
  width: 1.25rem;
  height: 1.25rem;
}

.sphere-spinner {
  color: rgb(156, 163, 175);
  animation: sphere-spinner-spin 1s linear infinite;
  width: 1.25rem;
  height: 1.25rem;
}

.sphere-spinner__track {
  opacity: 0.25;
}

.sphere-spinner__arc {
  opacity: 0.75;
}
