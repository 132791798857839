.sphere-button {
    background-color: transparent;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2.5rem;
    cursor: pointer;
    user-select: none;
    border-width: 2px;
    border-style: solid;
    border-color: rgba(44, 97, 249, 1);
    border-radius: 0.5rem;
    transition-property: background-color, color, border-color;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
}

.sphere-button-text {
    background: none;
    padding: 0;
    border: none;
    outline: 0;
    color: rgba(44, 97, 249, 1);
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    white-space: nowrap;
}

.sphere-button-text:enabled:hover {
    color: rgba(10, 63, 217, 1);
}

.sphere-button--contained.sphere-button--contained {
    background-color: rgba(44, 97, 249, 1);
    color: #ffffff;
}

.sphere-button--contained:enabled:hover {
    background-color: rgba(10, 63, 217, 1);
    border-color: rgba(10, 63, 217, 1);
}

.sphere-button--contained:focus {
    border-color: rgba(4, 169, 227, 1);
}

.sphere-button--contained--disabled.sphere-button--contained--disabled {
    cursor: default;
    border-color: rgba(228, 235, 255, 1);
    background-color: rgba(228, 235, 255, 1);
}

.sphere-button--outlined {
    color: rgba(44, 97, 249, 1);
}

.sphere-button--outlined:enabled:hover {
    border-color: rgba(10, 63, 217, 1);
    color: rgba(10, 63, 217, 1);
}

.sphere-button--outlined:focus {
    border-color: rgba(4, 169, 227, 1);
    color: rgba(4, 169, 227, 1);
}

.sphere-button--outlined--disabled.sphere-button--outlined--disabled {
    cursor: default;
    border: 1px solid rgba(217, 217, 217, 1);
    color: rgba(217, 217, 217, 1);
}

.sphere-button--loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sphere-button--secondary {
    color: #0f2a43;
    border: 1px solid #d6d9df;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    background: #fff;
}

.sphere-button--secondary:enabled:hover {
    background: #f5f7f9;
}

.sphere-button--merchant-verification {
    height: 1.75rem;
    background: linear-gradient(120deg, #2c61f8, #2a6af8, #1dcbfa);
    border: 1px solid transparent;
    background-clip: padding-box, border-box;
    box-decoration-break: clone;
    transition-property: background-color, color, border-color;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 0.5rem;
}

.sphere-button--merchant-verification:hover {
    /* Add the styles you want on hover */
    background: linear-gradient(120deg, #2350c2, #214bc2, #19a5c9);
}

.sphere-button--dark {
    color: #ffffff;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    background: #0f2a43;
    border: none;
}

.sphere-button--dark:hover {
    background: #000000;
}

.sphere-button--dark--disabled.sphere-button--dark--disabled {
    cursor: default;
    opacity: 0.8;
}

.sphere-button--dark--disabled:hover {
    background: #0f2a43;
}
